/* eslint-disable jsx-a11y/label-has-associated-control*/
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RichText } from "prismic-reactjs";
import * as prismic from "@prismicio/client";
import clsx from "clsx";
import { Row, Col, Button, Image } from "react-bootstrap";
import { client as prismicClient } from "../libs/prismic";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { Table, Thead, Tbody, Tr, Th, Td } from "../components/Table";
import TextInputField from "../components/TextInputField";
import {
  showAlert,
  hideAlert,
  NOT_VERIFIED_ADD_NEW_ACCOUNT,
} from "../store/reducers/alert";
import { actionTypes } from '../store/InvestorQualification/type';
import { actionTypes as appActionTypes } from '../store/App/type';
import { actionTypes as riaManagerActionTypes } from '../store/RiaUsersMgr/type';
import { actionTypes as accountActionTypes } from '../store/Accounts/type';
import {
  actionTypes as dialogActionTypes,
} from "../store/Dialog/type";

import { moduleTypes } from "../store/type";
import { getInvestCost, numberWithCommas } from "../utils";
import {
  getCurrentPrice
} from "../utils";

import "../styles/pages/manage-users.scss";
import AccountService from "../services/AccountService";

const accountService = new AccountService()

const ManageUsers = ({ profile, isLoggedIn }) => {
  const [accountsDoc, setAccountsDoc] = useState();
  const [isLoading, setLoading] = useState(false);
  const [adminAccount, setAdminAccount] = useState();
  const [accounts, setAccounts] = useState([]);
  const [riaUsers, setRiaUsers] = useState([]);
  const [sort, setSort] = useState(true)
  const [keyword, setKeyword] = useState("");
  const [currentPrices, setCurrentPrices] = useState({});
  const [permitNoInvite, setPermitNoInvite] = useState(false);

  const dispatch = useDispatch();

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const riaUsersMgr = useSelector(
    (state) => state?.riaUsersMgr
  );

  const loading = useSelector(
    (state) => state?.app?.loading
  );

  useEffect(() => {
    if (parentAccounts && Array.isArray(parentAccounts)) {
      const _accounts = parentAccounts.filter(el => el.hasOwnProperty('zipcode'))
      setAccounts(_accounts);
      const adminAccount = accounts?.find(el => el.type === 'ria');
      setAdminAccount(adminAccount);
    } 
  }, [parentAccounts]);

  useEffect(() => {
    async function fetchData(riaAcccount) {
      try {
        const riaUsers = await accountService.getRiaUsers(riaAcccount._id)
        console.log('riaUsers11', riaUsers);

        dispatch({ type: riaManagerActionTypes.INIT_RIA_USERS_MGR, value: { myRiaAccount: adminAccount, riaUsers: riaUsers.riaUsers } })
      } catch(e) {
      } finally {
      }
    }

    if (adminAccount) {
      fetchData(adminAccount);
    }
  }, [adminAccount]);

  useEffect(() => {
    console.log("riausersMgr sss");

    setRiaUsers(riaUsersMgr.riaUsers);
    setPermitNoInvite(riaUsersMgr.myRiaAccount?.joinOnlyInvite === 1 ? false: true)
  }, [riaUsersMgr]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await prismicClient().getSingle("ria_manage_users");
        setAccountsDoc(JSON.parse(JSON.stringify(response).replace('https://staging.opalvest.com/', '/')));

        const brandResponse = await prismicClient().get({
          predicates: prismic.predicates.at(
            "document.type",
            "opal_fund_partners"
          ),
          orderings: {
            field: "document.first_publication_date",
            direction: "desc",
          },
        });
        let _currentPrices = {};
        for (const brand of brandResponse?.results) {
          for (const opal of brand.data?.opals) {
            let key = `${brand.uid}-${opal.opal_name}`;  
            _currentPrices[key] = await getCurrentPrice(opal);
          }
        };  
        setCurrentPrices(_currentPrices);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    }
    fetchData();
    dispatch({ type: appActionTypes.LOAD_ACCOUNTS_DATA, value: null });
    return () => dispatch(hideAlert());
  }, [dispatch]);

  console.log("riaUsersMgr", riaUsersMgr);
  console.log("riaUsers", riaUsers);
  
  const myRiaAccountId = riaUsersMgr.myRiaAccount?._id;
  const myUser = riaUsers.find(el => el._id === myRiaAccountId);

  const adminUsers = riaUsers
    .filter(el => {
      return el._id != myRiaAccountId && (el.riaAdmin === 1 || el.riaAdmin === 2)
              && (el.user_info.firstName.toLowerCase().includes(keyword.toLowerCase()) ||
                el.user_info.lastName.toLowerCase().includes(keyword.toLowerCase()) ||
                el.user_info.email.toLowerCase().includes(keyword.toLowerCase()))
    });

  const generalUsers = riaUsers
    .filter(el => {
      return el._id != myRiaAccountId && !el.riaAdmin
              && (el.user_info.firstName.toLowerCase().includes(keyword.toLowerCase()) ||
                 el.user_info.lastName.toLowerCase().includes(keyword.toLowerCase()) ||
                 el.user_info.email.toLowerCase().includes(keyword.toLowerCase()))
    })
    .sort((a, b) => {
      let aName = sort ? `${a.user_info.firstName} ${a.user_info.lastName}` : `${a.user_info.lastName} ${a.user_info.firstName}`;
      let bName = sort ? `${b.user_info.firstName} ${b.user_info.lastName}` : `${b.user_info.lastName} ${b.user_info.firstName}`;
      aName = aName.toLowerCase();
      bName = bName.toLowerCase();

      return aName.localeCompare(bName);
    })

  const users = adminUsers.concat(generalUsers);
  if (!keyword) {
    users.unshift(myUser);
  } else {
    if (myUser.user_info.firstName.toLowerCase().includes(keyword.toLowerCase()) ||
    myUser.user_info.lastName.toLowerCase().includes(keyword.toLowerCase()) ||
    myUser.user_info.email.toLowerCase().includes(keyword.toLowerCase())) {
      users.unshift(myUser);
    }
  }

  const invitedUsers = (riaUsersMgr.myRiaAccount?.invitedUsers ?? [])
    .filter(el => {
      return el.email.toLowerCase().includes(keyword.toLowerCase())
    })
    .sort((a, b) => {
      let aEmail = a.email.toLowerCase();
      let bEmail = b.email.toLowerCase();
      return aEmail.localeCompare(bEmail);
    })

  const handlePermitNoInvite = async (flag) => {
    setPermitNoInvite(flag)
    accountService.setRiaJoinOnlyInvite(flag ? 0 : 1)
      .then((result) => {
        if (result) {
          // dispatch({ type: riaManagerActionTypes.UPDATE_MY_RIA_ACCOUNT, value: { joinOnlyInvite: result.joinOnlyInvite  } })
        }
      }).catch((err) => { })
  }

  const handleInviteUsers = () => {
    console.log("handle invite users");
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_invite_ria_users', account: riaUsersMgr.myRiaAccount } })
  };

  const editUser = (account) => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_edit_ria_user', account: account, riaCompany: riaUsersMgr.myRiaAccount?.riaCompany } })
  }

  const editInvitedUser = (invitedUser) => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_edit_ria_user', invitedUser: invitedUser, riaCompany: riaUsersMgr.myRiaAccount?.riaCompany } })
  }

  const deleteUser = async (account) => {

    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_delete_ria_user', account: account} })

  }

  const deleteInvitedUser = async (invitedUser) => {

    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_delete_ria_user', invitedUser: invitedUser} })
    
  }


  return !isLoading ? (
    <Layout>
      <Row className="pt-80 pb-80 mw-1440 mx-auto justify-content-center padding-mobile manage-user">
        <Col md={12}>
          <div className="rich-text header-text">
            <RichText render={accountsDoc?.data?.header} />
            <p>View and manage users associated with <b>{riaUsersMgr.myRiaAccount?.riaCompany}</b></p>
          </div>
        </Col>
        {(loading || !myUser) ? <Col md={12}><Loader /></Col> :
          (
            <>
              <Row className="justify-content-center">
                <Col md={6} className="order-md-last">
                  <div className="permit-no-invite d-flex flex-md-row flex-column" style={{display:"grid"}} >
                          
                    <span>Permit verified users to join without an invite?</span>
                    <div className="question-switch-container mx-2">
                      <Button 
                        className={`yes-switch ${permitNoInvite ? 'active' : ''}`}
                        onClick={() => handlePermitNoInvite(true)}
                      >
                        Yes
                      </Button>
                      <Button 
                        className={`no-switch ${!permitNoInvite ? 'active' : ''}`}
                        onClick={() => handlePermitNoInvite(false)}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col md={6} className="order-md-first">
                  <div className="accounts-button">
                    <div className="d-flex flex-md-row flex-column" style={{display:"grid"}} >
                      <div className="new-action-div">
                        <Image className="edit-icon" src="/assets/images/icon_add.svg" onClick={() => handleInviteUsers()} />
                        <Button className="name-label" onClick={() => handleInviteUsers()}>
                          Invite new users
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Col md={12} style={{ marginBottom: "20px" }}>
                <TextInputField
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={(e) => setKeyword(e.target.value)}
                  value={keyword}
                  placeholder="Search by Name"
                  className="search-input"
                />
              </Col>

              {(users.length > 0 || invitedUsers.length > 0) &&
                <>
                  <Col md={12}>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th style={{ width: '34%' }}>Name&nbsp;&nbsp;{users.length > 1 && <span onClick={() => setSort(prev => !prev)} className="clickable">{!sort ? "(Last, First)" : "(First, Last)"}</span>}</Th>
                          <Th style={{ width: '15%', textAlign: 'left' }}>Role</Th>
                          <Th style={{ width: '16%', textAlign: 'right' }}>Opal AUM</Th>
                          <Th style={{ width: '19%', textAlign: 'right' }}># of Accounts</Th>
                          <Th style={{ width: '17%' }}></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {users.map((user, idx) => {
                          return (
                            <Tr key={user._id}>
                              <Td className="accounts-div">
                                <div className="account-field">
                                  <label className="name-label">
                                    {`${user.user_info.firstName} ${user.user_info.lastName}`}
                                  </label>
                                  <label>{user.user_info.email}</label>
                                </div>
                              </Td>
                              <Td className="role-td" style={{ textAlign: 'left' }}>
                                <label>{user.riaAdmin ? 'Admin' : 'User'}</label>
                                {!user.riaAdmin && (
                                  <Button onClick={() => editUser(user)}>
                                    <Image className="edit-icon" src="/assets/images/icon_edit.svg"/>
                                  </Button>
                                )}
                              </Td>
                              <Td style={{ textAlign: 'right' }}>
                                <label>${numberWithCommas(user.user_info.child_accounts.reduce((sum, account) => {
                                      return sum + getInvestCost(account, {}, currentPrices);
                                    }, 0), false)}
                                </label>
                              </Td>
                              <Td style={{ textAlign: 'right' }}>
                                <label>{user.user_info.child_accounts.length - 1}</label>
                              </Td>
                              <Td className="action-div" style={{ textAlign: 'right' }}>
                                {!user.riaAdmin && user.user_info.child_accounts.length <= 1 && (
                                  <Button onClick={() => deleteUser(user)}>
                                    <Image
                                      className="edit-icon"
                                      src="/assets/images/icon_delete.png"
                                    />
                                  </Button>
                                )}
                              </Td>
                            </Tr>
                          );
                        })}

                        {invitedUsers.map((invitedUser, idx) => {
                          return (
                            <Tr key={invitedUser._id}>
                              <Td className="accounts-div">
                                <div className="account-field">
                                  <label>{invitedUser.email}</label>
                                </div>
                              </Td>
                              <Td className="role-td" style={{ textAlign: 'left' }}>
                                <label>{invitedUser.isAdmin ? 'Admin' : 'User'}</label>
                                {!invitedUser.isAdmin && (
                                  <Button onClick={() => editInvitedUser(invitedUser)}>
                                    <Image className="edit-icon" src="/assets/images/icon_edit.svg"/>
                                  </Button>
                                )}
                              </Td>
                              <Td style={{ textAlign: 'right', paddingTop: '10px', paddingBottom: '10px' }} colSpan={2} >
                                {invitedUser.status === 0 && (
                                  <div className="invited-user-0">
                                    <label className="caption">INVITED</label>
                                    <label>{`by ${myUser?.user_info?.firstName} ${myUser?.user_info?.lastName}`}</label>
                                  </div>
                                )}
                                {invitedUser.status === 1 && (
                                  <div className="invited-user-1">
                                    <label className="caption">WAITING ON USER TO VERIFY</label>
                                  </div>
                                )}
                              </Td>
                              <Td className="action-div" style={{ textAlign: 'right' }}>
                                  <Button onClick={() => deleteInvitedUser(invitedUser)}>
                                    <Image
                                      className="edit-icon"
                                      src="/assets/images/icon_delete.png"
                                    />
                                  </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </Col>
              </>}
              {(users.length === 0 && invitedUsers.length === 0) && (
              <>
                <Col md={12} className="no-result">
                  <Table>
                    <Thead>
                      <Tr>
                        <Th style={{ width: '34%' }}>Name</Th>
                        <Th style={{ width: '15%', textAlign: 'left' }}>Role</Th>
                        <Th style={{ width: '16%', textAlign: 'right' }}>Opal AUM</Th>
                        <Th style={{ width: '19%', textAlign: 'right' }}># of Accounts</Th>
                        <Th style={{ width: '17%' }}></Th>                          
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td style={{ paddingTop: '10px', paddingBottom: '30px' }}>
                          {(riaUsers.length <= 1) && <label>No users have been onboarded.</label>}
                          {(riaUsers.length > 1) && <label>No users match your search.</label>}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Col>
              </>
              )}
            </>
          )
        }
      </Row>
    </Layout>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  state,
  profile: state.app.profile,
  isLoggedIn: state[moduleTypes.APP].isLoggedIn,
  disqualificationOfAccredited: state[moduleTypes.INVESTOR_QUALIFICATION].disqualificationOfAccredited,
})


export default connect(mapStateToProps)(ManageUsers);
